import { Text } from "@/components/common";
import { FC } from "react";
import styled from "styled-components";

const Error = styled(Text)`
  display: block;
  margin-top: 4px;
  padding: 0 15px;
`;

export const ValidationError: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  if (!children) return null;
  return (
    <Error as="span" $type="label" $color="red" className={className}>
      {children}
    </Error>
  );
};
