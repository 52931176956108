import styled, { css } from "styled-components";
import { Text } from "./Text";
export { Text } from "./Text";
export { Button } from "./Button";
export { ValidationError } from "./ValidationError";
export { Loader } from "./Loader";
export { Checkbox } from "./Checkbox";
export { WysiwygContent } from "./WysiwygContent";

export const Container = styled.div`
  width: 1440px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      width: 1140px;
    }
    ${theme.breakpoints.smallDesktop} {
      width: 960px;
    }
    ${theme.breakpoints.tablet} {
      width: 720px;
    }
  `}
`;

export const Input = styled.input<{ $hasError?: boolean }>`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: 0.2s ease-out all;
  ${({ theme }) => css`
    background: ${theme.colors.bg};
    color: ${theme.colors.black};
    font-weight: ${theme.typography.labelNormal.fontWeight};
    line-height: ${theme.typography.labelNormal.lineHeight};
    font-size: ${theme.typography.labelNormal.fontSize};
    font-family: ${theme.typography.labelNormal.fontFamily};
    &::placeholder {
      color: #828488;
    }
    &:focus {
      outline: none;
      border-color: #ebebeb;
      background: ${theme.colors.white};
    }
    &:disabled {
      background: ${theme.colors.bg};
      cursor: not-allowed;
    }
    ${theme.breakpoints.phone} {
      font-size: 16px;
    }
  `}
  ${({ theme, $hasError }) =>
    $hasError &&
    css`
      border-color: ${theme.colors.red};
      background: ${theme.colors.white};
    `}
`;

export const Textarea = styled(Input).attrs({ as: "textarea" })`
  min-height: 105px;
  height: auto;
  padding: 10px 15px;
`;

export const Label = styled(Text).attrs({ as: "label", $type: "labelNormal" })`
  padding: 0 15px;
  display: block;
  margin: 0 0 6px;
`;

export const Row = styled.div<{ $gutter?: number }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${({ $gutter = 15 }) => $gutter}px;
`;

type ColumnsRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const Column = styled.div<{ $gutter?: number; $cols: ColumnsRange }>`
  min-height: 1px;
  width: 100%;
  max-width: ${({ $cols }) => ($cols / 12) * 100}%;
  flex: 0 0 ${({ $cols }) => ($cols / 12) * 100}%;
  padding: 0 ${({ $gutter = 15 }) => $gutter}px;
`;

export const Card = styled.article`
  box-shadow: 0 0 15px rgba(32, 37, 44, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
`;

export const SrOnly = styled.h1`
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
