import Swal from "sweetalert2";

export const errorNotification = (
  title = "Ooops...",
  text = "Something went wrong!"
) => {
  Swal.fire({
    icon: "error",
    title,
    text,
    confirmButtonColor: "#ff1919",
    iconColor: "#ff1919",
  });
};

export const successNotification = (title: string, text?: string) => {
  Swal.fire({
    icon: "success",
    title,
    text,
    confirmButtonColor: "#ff1919",
  });
};
