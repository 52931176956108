import { HTMLProps, forwardRef } from "react";
import styled, { css } from "styled-components";

import { Text } from "@/components/common";

const Checkmark = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #c7c7c7;
  transition: 0.2s ease-out border-color;
  &::after {
    content: "";
    position: absolute;
    display: none;
    background: ${({ theme }) => theme.colors.red};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 0;
    width: 7px;
    height: 7px;
    margin: auto;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      top: 0;
    }
  `}
`;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    ${Checkmark} {
      border-color: ${({ theme }) => theme.colors.red};
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-left: 25px;
    }
  `}
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
    top: 4px;
    left: 4px;
    &:checked {
      ~ ${Checkmark} {
        border-color: ${({ theme }) => theme.colors.red};
        &::after {
          display: block;
        }
      }
    }
  }
`;

const LabelText = styled(Text)`
  letter-spacing: 0.33px;
`;

interface Props extends HTMLProps<HTMLInputElement> {
  id: string;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <Label htmlFor={props.id} className={className}>
        <LabelText $type="labelNormal" as="span">
          {children}
        </LabelText>
        <input type="checkbox" ref={ref} {...props} />
        <Checkmark />
      </Label>
    );
  }
);
