import { createModel } from "@rematch/core";

import {
  UserResource,
  LoginRequest,
  RegisterRequest,
  CompleteSignUpRequest,
} from "@/types/api";
import { RootModel } from ".";
import { api, csrfProtectedApi } from "@/utilities/api";
import { FormData as ProfileFormData } from "@/pages/Profile";

interface State {
  user: UserResource | null;
}

const initialState: State = {
  user: null,
};

export const authentication = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, user: UserResource | null) {
      return { ...state, user };
    },

    updateUserProperties(state: State, properties: Partial<UserResource>) {
      return {
        ...state,
        user: {
          ...state.user!,
          ...properties,
        },
      };
    },
  },
  effects: (dispatch) => ({
    async login(credentials: LoginRequest) {
      await csrfProtectedApi<never>((api) =>
        api.post<LoginRequest, never>("/login", credentials)
      );
      await this.fetchCurrentUser();
    },

    async fetchCurrentUser() {
      const user = await api
        .get<never, UserResource>("/v1/auth")
        .catch(() => null);
      dispatch.authentication.setUser(user);
    },

    async logout() {
      await csrfProtectedApi((api) => api.post("/logout"));
      dispatch.authentication.setUser(null);
    },

    async register(userData: RegisterRequest) {
      const user = await api.post<RegisterRequest, UserResource>(
        "/v1/auth/register",
        userData
      );
      await this.login(userData);

      return user.id;
    },

    async updateProfile(data: ProfileFormData) {
      const formData = new FormData();
      (Object.keys(data) as Array<keyof ProfileFormData>).forEach((key) => {
        if (key === "cover" || key === "avatar") {
          if (!data[key]!.length) return;
          return formData.append(key, data[key]![0]);
        }
        if (key === "socials") {
          return (
            Object.keys(data[key]) as Array<keyof ProfileFormData["socials"]>
          ).forEach((type) => {
            formData.append(`socials[${type}]`, data[key][type]);
          });
        }
        formData.append(key, data[key] as string);
      });
      formData.append("_method", "PUT");
      await api.post("/v1/auth/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      await this.fetchCurrentUser();
    },

    async completeSignup(data: CompleteSignUpRequest) {
      await api.post<CompleteSignUpRequest, { success: boolean }>(
        "/v1/auth/complete-signup",
        data
      );
      await this.fetchCurrentUser();
    },

    async followContributor(contributorId: number) {
      const { followed_contributors_ids } = await api.post<
        never,
        { followed_contributors_ids: number[] }
      >(`/v1/contributors/${contributorId}/followers`);
      dispatch.authentication.updateUserProperties({
        followed_contributors_ids,
      });
    },

    async unfollowContributor(contributorId: number) {
      const { followed_contributors_ids } = await api.delete<
        never,
        { followed_contributors_ids: number[] }
      >(`/v1/contributors/${contributorId}/followers`);
      dispatch.authentication.updateUserProperties({
        followed_contributors_ids,
      });
    },

    async bookmarkVideo(videoId: number) {
      const { bookmarked_videos_ids } = await api.post<
        never,
        { bookmarked_videos_ids: number[] }
      >(`/v1/videos/${videoId}/bookmarks`);
      dispatch.authentication.updateUserProperties({ bookmarked_videos_ids });
    },

    async unbookmarkVideo(videoId: number) {
      const { bookmarked_videos_ids } = await api.delete<
        never,
        { bookmarked_videos_ids: number[] }
      >(`/v1/videos/${videoId}/bookmarks`);
      dispatch.authentication.updateUserProperties({ bookmarked_videos_ids });
    },

    async likeVideo(videoId: number) {
      const { liked_videos_ids } = await api.post<
        never,
        { liked_videos_ids: number[] }
      >(`/v1/videos/${videoId}/likes`);
      dispatch.authentication.updateUserProperties({ liked_videos_ids });
    },

    async unlikeVideo(videoId: number) {
      const { liked_videos_ids } = await api.delete<
        never,
        { liked_videos_ids: number[] }
      >(`/v1/videos/${videoId}/likes`);
      dispatch.authentication.updateUserProperties({ liked_videos_ids });
    },
  }),
});
