import { pxToRem } from "@/utilities/helpers";
import { createGlobalStyle, DefaultTheme, css } from "styled-components";

export const Breakpoints = {
  min: {
    tablet: 768,
    smallDesktop: 992,
    mediumDesktop: 1230,
    largeDesktop: 1440,
  },
  max: {
    phone: 767,
    tablet: 991,
    smallDesktop: 1229,
    mediumDesktop: 1439,
  },
} as const;

const typography = {
  textOpenSans: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 400,
    fontSize: `${pxToRem(15)}rem`,
    lineHeight: `${pxToRem(23)}rem`,
  },
  textPoppins: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: `${pxToRem(15)}rem`,
    lineHeight: `${pxToRem(23)}rem`,
  },
  textPoppinsLight: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 300,
    fontSize: `${pxToRem(15)}rem`,
    lineHeight: `${pxToRem(23)}rem`,
  },
  textPoppinsSemiBold: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: `${pxToRem(15)}rem`,
    lineHeight: `${pxToRem(23)}rem`,
  },
  textPoppinsBold: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    fontSize: `${pxToRem(15)}rem`,
    lineHeight: `${pxToRem(23)}rem`,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.2",
    fontWeight: 300,
    fontSize: `${pxToRem(12)}rem`,
  },
  labelNormal: {
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.6",
    fontWeight: 400,
    fontSize: `${pxToRem(12)}rem`,
  },
  labelSemiBold: {
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.2",
    fontWeight: 600,
    fontSize: `${pxToRem(12)}rem`,
  },
  labelBold: {
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.2",
    fontWeight: 700,
    fontSize: `${pxToRem(12)}rem`,
  },
  heading1: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    fontSize: `${pxToRem(21)}rem`,
    lineHeight: `${pxToRem(25)}rem`,
  },
  heading2: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 700,
    fontSize: `${pxToRem(17)}rem`,
    lineHeight: `${pxToRem(20)}rem`,
  },
};

const colors = {
  black: "#20252c",
  red: "#ff1919",
  white: "#ffffff",
  bg: "#f8f8f8",
  gray: "#969698",
  darkGray: "#20252c",
  lightGray: "#f3f3f4",
};

const breakpoints = {
  phone: `@media(max-width: ${Breakpoints.max.phone}px)`,
  tablet: `@media(max-width: ${Breakpoints.max.tablet}px)`,
  smallDesktop: `@media(max-width: ${Breakpoints.max.smallDesktop}px)`,
  mediumDesktop: `@media(max-width: ${Breakpoints.max.mediumDesktop}px)`,
  min: {
    tablet: `@media(min-width: ${Breakpoints.min.tablet}px)`,
    smallDesktop: `@media(min-width: ${Breakpoints.min.smallDesktop}px)`,
    mediumDesktop: `@media(min-width: ${Breakpoints.min.mediumDesktop}px)`,
    largeDesktop: `@media(min-width: ${Breakpoints.min.largeDesktop}px)`,
  },
};

const zIndex = {
  onTop: 2,
  dropdown: 5,
  modal: 10,
};

const theme: DefaultTheme = {
  typography,
  colors,
  breakpoints,
  zIndex,
};

export default theme;

export type Color = keyof DefaultTheme["colors"];
export type Typography = keyof DefaultTheme["typography"];

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      html {
        font-size: 0.9rem;
      }
    }
  `}
  body {
    background-color:${(p) => p.theme.colors.bg};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *, &::after, &::before {
    box-sizing: border-box;
    font: inherit;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
  .swal2-popup {
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
  }
  .swal2-title {
    color: ${({ theme }) => theme.colors.black};
  }
  .swal2-styled {
    font-weight: 700;
  }
  .swal2-styled.swal2-confirm {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    box-shadow: none !important;
  }
`;
