import axios, { AxiosError } from "axios";
import { Path, UseFormSetError } from "react-hook-form";

import { ValidationErrorResponse } from "@/types";
import { errorNotification } from "@/utilities/alerts";
import Swal from "sweetalert2";

export const numberWithCommas = (number: number) => {
  return number.toLocaleString("en-US");
};

export const pxToRem = (pixels: number) => {
  return pixels / 16;
};

export const isValidExtension = (
  fileName: string,
  validExtensions: string[]
) => {
  const fileNameParts = fileName.split(".");
  if (!fileNameParts.length) return false;
  const extension = fileNameParts[fileNameParts.length - 1];
  return validExtensions.includes(extension.toLowerCase());
};

export const handleFormErrors = <T>(
  error: unknown,
  setError: UseFormSetError<T>
) => {
  if (axios.isAxiosError(error)) {
    const err = error as AxiosError<ValidationErrorResponse<T>>;
    if (!err.response?.data?.errors) {
      return errorNotification();
    }
    (Object.keys(err.response.data.errors) as Array<keyof T>).forEach((key) => {
      setError(key as unknown as Path<T>, {
        type: "manual",
        message: err.response!.data.errors[key][0],
      });
    });
    return errorNotification(
      "Oops!",
      "We couldn't submit your form yet. Please fix the incorrect fields above."
    );
  }
  return errorNotification();
};

export const copyToClipboard = async (textToCopy: string) => {
  await navigator.clipboard?.writeText(textToCopy);
  Swal.fire({
    icon: "success",
    title: "Copied to clipboard!",
    timer: 1800,
    showConfirmButton: false,
  });
};
