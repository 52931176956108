import { Color, Typography } from "@/utilities/theme";
import styled, { css } from "styled-components";

export interface TextProps {
  $color?: Color;
  $type?: Typography;
}

export const Text = styled.p<TextProps>`
  ${({ theme, $color = "black", $type = "textOpenSans" }) => css`
    color: ${theme.colors[$color]};
    font-weight: ${theme.typography[$type].fontWeight};
    line-height: ${theme.typography[$type].lineHeight};
    font-size: ${theme.typography[$type].fontSize};
    font-family: ${theme.typography[$type].fontFamily};
  `}
`;
