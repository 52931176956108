import styled, { css } from "styled-components";

import { Text } from "@/components/common";
import { pxToRem } from "@/utilities/helpers";

export const WysiwygContent = styled(Text).attrs({ as: "div" })`
  ${({ theme }) => css`
    p,
    ol,
    ul,
    h1,
    h2,
    h3 {
      margin: 0 0 ${pxToRem(30)}rem;
    }
    h1,
    h2,
    h3 {
      font-family: ${theme.typography.heading2.fontFamily};
      font-size: ${theme.typography.heading2.fontSize};
      line-height: ${theme.typography.heading2.lineHeight};
      font-weight: ${theme.typography.heading2.fontWeight};
    }
    h1 {
      font-size: ${theme.typography.heading1.fontSize};
      line-height: ${theme.typography.heading1.lineHeight};
    }
    a {
      text-decoration: underline;
      transition: 0.2s ease-out color;
      &:hover {
        color: ${theme.colors.red};
      }
    }
    ul {
      ul {
        margin: ${pxToRem(10)}rem;
      }
      li {
        padding-left: 15px;
        position: relative;
        margin-bottom: ${pxToRem(10)}rem;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: ${theme.colors.red};
          margin: auto;
          top: 10px;
          left: 0;
        }
      }
    }
    figure.table {
      margin: 0 -30px ${pxToRem(30)}rem;
      ${theme.breakpoints.tablet} {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
      ${theme.breakpoints.phone} {
        margin: 0 -15px ${pxToRem(30)}rem;
      }
    }
    table {
      width: 100%;
      th {
        white-space: nowrap;
        vertical-align: middle;
        text-align: left;
        padding: 10px 30px;
        background: ${theme.colors.red};
        color: #fff;
        text-transform: uppercase;
        font-family: ${theme.typography.labelBold.fontFamily};
        font-size: ${theme.typography.labelBold.fontSize};
        line-height: ${theme.typography.labelBold.lineHeight};
        font-weight: ${theme.typography.labelBold.fontWeight};
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
        ${theme.breakpoints.smallDesktop} {
          padding: 10px 20px;
        }
        ${theme.breakpoints.tablet} {
          &:first-child,
          &:last-child {
            border-radius: 0;
          }
        }
      }
      td {
        padding: 25px 30px;
        border: 2px solid #fff;
        background: ${theme.colors.bg};
        ${theme.breakpoints.smallDesktop} {
          padding: 20px;
        }
        ${theme.breakpoints.phone} {
          padding: 10px 20px;
          min-width: 200px;
        }
      }
      tbody {
        tr {
          &:first-child {
            border-top: 0;
          }
          &:last-child {
            td {
              border-bottom: 0;
              &:first-child {
                border-bottom-left-radius: 10px;
              }
              &:last-child {
                border-bottom-right-radius: 10px;
              }
              ${theme.breakpoints.tablet} {
                &:first-child,
                &:last-child {
                  border-radius: 0;
                }
              }
            }
          }
        }
      }
    }
  `}
`;
