import { Models } from "@rematch/core";
import { authentication } from "./authentication";

export interface RootModel extends Models<RootModel> {
  authentication: typeof authentication;
}

export const models: RootModel = {
  authentication,
};
