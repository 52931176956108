import { FC } from "react";
import styled from "styled-components";

import { ReactComponent as Spinner } from "@/assets/icons/spinner.svg";
import { Text } from "@/components/common";

const StyledLoader = styled.div<{ $withMargins?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ $withMargins }) => ($withMargins ? "15px 0" : 0)};
  svg {
    margin-right: 15px;
  }
`;

export const Loader: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <StyledLoader $withMargins={!children} className={className}>
      <Spinner />
      <Text $type="label" $color="gray">
        {children || "Loading"}
      </Text>
    </StyledLoader>
  );
};
