import axios, { AxiosError } from "axios";

const config = {
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
};

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api`,
  ...config,
});

api.interceptors.response.use(
  (response) => response.data,
  (error: AxiosError) => {
    if (error.response?.status === 401 || error.response?.status === 419) {
      if (error?.response?.config?.url !== "/v1/auth") {
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use((config) => {
  config!.headers!.ACTION_SOURCE_URL =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  return config;
});

const basicAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  ...config,
});

basicAxiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);

export const csrfProtectedApi = async <TResponse = never>(
  callback: (api: typeof basicAxiosInstance) => Promise<TResponse>
) => {
  await basicAxiosInstance.get("/sanctum/csrf-cookie");
  return callback(basicAxiosInstance);
};
