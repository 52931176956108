import { FC, ComponentProps } from "react";
import { Link } from "react-router-dom";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";

import { Text } from "@/components/common";
import { TextProps } from "@/components/common/Text";
import { ReactComponent as Spinner } from "@/assets/icons/button-spinner.svg";

const StyledSpinner = styled(Spinner)`
  position: absolute;
  right: 15px;
  margin: 0 !important;
`;

type StyledButton = StyledComponent<
  "button" | "a" | typeof Link,
  DefaultTheme,
  TextProps & { $small: boolean; $background: "red" | "gray" },
  never
>;

const StyledText = styled(Text).attrs({
  $color: "white",
})<{ $small: boolean; $background: "red" | "gray" }>`
  position: relative;
  padding: 0 60px;
  line-height: 0;
  height: 48px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-transform: uppercase;
  transition: 0.2s ease-out all;
  border: 0;
  > svg {
    margin-right: 10px;
  }
  ${({ theme, $background }) =>
    $background === "red" &&
    css`
      background: ${theme.colors.red};
      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    `}
  ${({ theme, $background }) =>
    $background === "gray" &&
    css`
      background: #828488;
      &:hover {
        background: ${theme.colors.red};
      }
    `}
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 42px;
      padding: 0 25px;
    }
  `}
  ${({ $small, theme }) =>
    $small &&
    css`
      padding: 0 15px;
      height: 32px;
      ${theme.breakpoints.phone} {
        height: 26px;
      }
      ${StyledSpinner} {
        width: 15px;
        right: 8px;
      }
    `}
` as StyledButton;

type X = ComponentProps<typeof StyledText>;

interface ButtonProps extends X {
  as?: "button" | "a" | typeof Link | "label";
  small?: boolean;
  color?: "gray" | "red";
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  as = "button",
  children,
  small = false,
  color = "red",
  loading,
  ...props
}) => (
  <StyledText
    $type={small ? "labelBold" : "heading2"}
    $background={color}
    $small={small}
    as={as}
    {...props}
  >
    {children}
    {loading && <StyledSpinner />}
  </StyledText>
);
